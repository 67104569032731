import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { Person9, Image7, Person10, Person8 } from "../../components/Images";
const Component = () => {
  const navigate = useNavigate();
  const CategoryItem = (item, image) => {
    return (
      <article
        className="ImageButtonBG"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div>
          <div
            className="ButtonContent ButtonDarkMini"
            onClick={() => navigate("/coleccion")}
          >
            {item}
          </div>
        </div>
      </article>
    );
  };
  return (
    <>
      <Breadcrumb title="TOPS" />
      <section className="CategoryBox">
        {CategoryItem("Tank Tops", Person9)}
        {CategoryItem("T-Shirts", Image7)}
        {CategoryItem("Hoodies", Person10)}
        {CategoryItem("Jackets", Person8)}
      </section>
    </>
  );
};

export default Component;
