import React from "react";
const Component = ({ title, perfil, databc = [] }) => {
  return (
    <div className={`CategoryTitle${perfil ? " PerfilSection" : ""}`}>
      <ul className="Breadcrumb">
        <li>Home</li>
        {perfil ? <li>Mi Perfil</li> : <li>Tops</li>}
        {databc.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      {title && <h3>{title}</h3>}
    </div>
  );
};

export default Component;
