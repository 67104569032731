import React from "react";
import { useNavigate, Link } from "react-router-dom";
const Component = ({ image, description = "" }) => {
  const navigate = useNavigate();
  return (
    <div
      key={`${performance.now()}-${Math.random()}`}
      onMouseUp={() => navigate("/detalles-producto")}
    >
      <figure>
        <picture>
          <source media="(min-width: 768px)" srcSet={image} />
          <source media="(max-width: 767px)" srcSet={image} />
          <img src={image} alt={description} />
        </picture>
        <figcaption>{description}</figcaption>
      </figure>
    </div>
  );
};

export default Component;
