import React from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Image9,
  Image1,
  Person3,
  Image8,
  Person7,
  Image6,
  Person11,
  Image5,
} from "../../components/Images";
import InstagramBox from "../../components/InstagramBox";
import Figure from "../../components/Figure";
const Component = () => {
  const navigate = useNavigate();
  const RowWithButton = (text, image) => {
    return (
      <div style={{ backgroundImage: `url(${image})` }}>
        <div className="MainButton">
          <div className="ButtonLight2" onClick={() => navigate("/category")}>
            {text}
            <i className="icon-ArrowRight"></i>
          </div>
        </div>
      </div>
    );
  };
  return (
    <section>
      <article className="MainImageBox">
        <div className="MainButton">
          <div className="ButtonLight2" onClick={() => navigate("/coleccion")}>
            VER COLECCIÓN
            <i className="icon-ArrowRight"></i>
          </div>
        </div>
      </article>
      <article className="MainBox">
        <div>
          <div className="MainContentItems MCtemsButtons">
            {RowWithButton("TOPS", Image9)}
            {RowWithButton("BOTTOMS", Image1)}
            {RowWithButton("JEWERLY", Person3)}
            {RowWithButton("ACCESORIES", Image8)}
          </div>
        </div>
      </article>
      <article className="Sticky">
        <div
          className="ButtonContent ButtonDark"
          onClick={() => navigate("/contacto")}
        >
          Contact Us
        </div>
      </article>
      <article className="MainBox">
        <div>
          <div className="WeCreateToBeYourself"></div>
          <InstagramBox />
        </div>
      </article>
      <article className="MainBox">
        <div>
          <div className="NewArrivals"></div>
          <div className="MainContentItems">
            {<Figure image={Person7} />}
            {<Figure image={Image6} />}
            {<Figure image={Person11} />}
            {<Figure image={Image5} />}
            {<Figure image={Person7} />}
            {<Figure image={Image9} />}
            {<Figure image={Person11} />}
            {<Figure image={Image5} />}
            {/*<div style={{ backgroundImage: `url(${Person7})` }}></div>*/}
          </div>
        </div>
      </article>
      <article className="AboutBox">
        <div className="AboutUsBox">
          <div className="AboutUs"></div>
          <p>
            A veces atravesar una crisis es un buen motivo para empezar algo
            positivo. Es así como la historia de Arte On U comenzó de la mano de
            su fundador y Director Artístico, Miguel Pérez Lem. En 2020....
          </p>
          <div
            className="ButtonContent ButtonLightMini"
            onClick={() => navigate("/about")}
          >
            LEER MÁS
            <i className="icon-ArrowRight"></i>
          </div>
        </div>
        <div className="ArtFansBox">
          <div className="ArtFans"></div>
          <div className="StrokeDash"></div>
          <div>
            <ul>
              <li>10% off your first online order.</li>
              <li>Accumulate points and earn benefits</li>
              <li>Shopping assistance with the designer</li>
              <li>And more</li>
            </ul>
          </div>
          <div
            className="ButtonContent ButtonLightMini"
            onClick={() => navigate("/login")}
          >
            Sign Up
            <i className="icon-ArrowRight"></i>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Component;
