import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

const Component = () => {
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const contentRef = useRef(null);
  useEffect(() => {
    const handleMove = (clientX) => {
      if (dragStartX !== 0) {
        const dx = clientX - dragStartX;
        setScrollLeft((prevMargin) => {
          const totalWidth = contentRef.current.scrollWidth;
          const containerWidth = contentRef.current.clientWidth;
          const newMargin = prevMargin - dx;
          return Math.min(0, Math.max(containerWidth - totalWidth, newMargin));
        });
        setDragStartX(clientX);
      }
    };
    const handleMouseMove = (e) => handleMove(e.clientX);
    const handleTouchMove = (e) => handleMove(e.touches[0].clientX);

    const handleEnd = () => setDragStartX(0);

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleEnd);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleEnd);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleEnd);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleEnd);
    };
  }, [dragStartX]);
  const handleStart = (clientX) => setDragStartX(clientX);
  const handleMouseDown = (e) => handleStart(e.clientX);
  const handleTouchStart = (e) => handleStart(e.touches[0].clientX);
  return (
    <nav
      className="Navigation"
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      <ul
        ref={contentRef}
        style={{
          left: `${scrollLeft}px`,
        }}
      >
        <li>
          <Link to="/404">Bañadores</Link>
        </li>
        <li>
          <Link to="/404">Fullprint</Link>
        </li>
        <li>
          <Link to="/404">Sport</Link>
        </li>
        <li>
          <Link to="/404">Accesorios</Link>
        </li>
        <li>
          <Link to="/404">Vestidos</Link>
        </li>
        <li>
          <Link to="/404">Categoría 1</Link>
        </li>
        <li>
          <Link to="/404">Categoría 2</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Component;
