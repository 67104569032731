import React from "react";
import { useNavigate, Link } from "react-router-dom";

const Component = () => {
  const navigate = useNavigate();
  return (
    <div className="GroupButton">
      <div className="ButtonLightMini">
        <span className="IconGoogle">
          <i className="icon-GoogleRed"></i>
          <i className="icon-GoogleYellow"></i>
          <i className="icon-GoogleGreen"></i>
          <i className="icon-GoogleBlue"></i>
        </span>
        Continuar con Google
      </div>
      <div className="ButtonLightMini">
        <i className="icon-Facebook" style={{ color: "#1877f2" }}></i>
        Continuar con Facebook
      </div>
      <div className="ButtonLightMini" onClick={() => navigate("/login-email")}>
        <i className="icon-MailOpen"></i>
        Continuar con email
      </div>
    </div>
  );
};

export default Component;
