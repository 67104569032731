import React, { useRef } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import ColeccionItem from "./ColeccionItem";
import { Person9, Image5, Person11, Image9 } from "../../components/Images";
const Component = ({ title, coleccionesMenu, databc = [], noposition }) => {
  const coleccionPosition = useRef(null);
  function SetPositionColeccion(event) {
    const pos = ["Vertical", "Horizontal"];
    pos[2] = event.target.dataset.n.split(",");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][0]]}`)
      .classList.add("Selected");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][1]]}`)
      .classList.remove("Selected");
    coleccionPosition.current.classList.toggle("ColeccionHorizontal");
    coleccionPosition.current.classList.toggle("ColeccionVertical");
  }
  function ColeccionMenuItem(data) {
    return (
      <section className="ColeccionMenu">
        <div>
          {coleccionesMenu != 0 && (
            <>
              <p>Colecciones:</p>
              <ul>
                <li className="Selected">Todas</li>
                <li>Pride</li>
                <li>Abstract</li>
                <li>Animals</li>
                <li>Love</li>
                <li>Ocean</li>
                <li>Senses</li>
              </ul>
            </>
          )}
        </div>
        <div>
          <span
            className="icon-Vertical"
            data-n="0,1"
            onClick={SetPositionColeccion}
          >
            Vertical
          </span>
          <span
            className="icon-Horizontal Selected"
            data-n="1,0"
            onClick={SetPositionColeccion}
          >
            Horizontal
          </span>
        </div>
      </section>
    );
  }
  function ColeccionPagination() {
    return (
      <ul>
        <li>1</li>
        <li>2</li>
        <li className="Selected">3</li>
        <li>4</li>
        <li>5</li>
      </ul>
    );
  }
  return (
    <>
      <Breadcrumb title={title} databc={databc} />
      {!noposition && ColeccionMenuItem()}
      <section
        className="ColeccionItems ColeccionVertical"
        ref={coleccionPosition}
      >
        {<ColeccionItem image={Person9} />}
        {<ColeccionItem image={Image5} />}
        {<ColeccionItem image={Person11} />}
        {<ColeccionItem image={Image9} />}
        {<ColeccionItem image={Person9} />}
        {<ColeccionItem image={Image5} />}
        {<ColeccionItem image={Person11} />}
        {<ColeccionItem image={Image9} />}
      </section>
      <section className="ColeccionPagination">
        <div className="icon-CaretLeft"></div>
        {ColeccionPagination()}
        <div className="icon-CaretRight"></div>
      </section>
    </>
  );
};

export default Component;
