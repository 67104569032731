import React from "react";
import { Person2, Person9, Person12 } from ".//Images";
const Component = ({ mini }) => {
  const igimages = [Person2, Person9, Person12, Person9, Person12];
  const RotateIG = (event, setindex) => {
    setindex = setindex === undefined ? false : setindex;
    const InstagramBox = event.currentTarget.parentNode;
    if (window.getComputedStyle(InstagramBox).display === "block") {
      let childs = InstagramBox.children;
      childs = Array.from(childs);
      const clickedIndex =
        setindex !== undefined ? setindex : childs.indexOf(event.currentTarget);
      if (clickedIndex === 2) {
        const firstChild = childs[0];
        InstagramBox.appendChild(firstChild);
      }
      if (clickedIndex === 0) {
        const lastChild = childs[childs.length - 1];
        InstagramBox.insertBefore(lastChild, childs[0]);
      }
    }
  };
  let startX = 0;
  let endX = 0;
  const handleMouseDown = (e) => {
    startX = e.clientX;
  };
  const handleMouseUp = (e) => {
    endX = e.clientX;
    const difference = endX - startX;
    if (difference > 0) {
      RotateIG(e, 0);
    } else {
      RotateIG(e, 2);
    }
  };
  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };
  const handleTouchEnd = (e) => {
    endX = e.changedTouches[0].clientX;
    const difference = endX - startX;
    if (difference > 0) {
      RotateIG(e, 0);
    } else {
      RotateIG(e, 2);
    }
  };
  function InstagramItem(image, text) {
    return (
      <div
        className="InstagramItem"
        onClick={RotateIG}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div style={{ backgroundImage: `url(${image})` }}></div>
        <p className="TextLeft">
          <i className="icon-Instagram"></i>
          <span>
            @Ryan_9875 I really loved my new outfit, thank you @ArtOnU for
            making the most beautiful trip possible
          </span>
        </p>
      </div>
    );
  }
  return (
    <div className={`InstagramBox${mini ? " IGBoxMini" : ""}`}>
      {igimages.map((image) => InstagramItem(image))}
    </div>
  );
};

export default Component;
