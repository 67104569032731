import React from "react";
import { AddToHeart } from "../../components/Funciones";
import Figure from "../../components/Figure";
const Component = ({ image, heart }) => {
  return (
    <>
      <article>
        {/* <div style={{ backgroundImage: `url(${image})` }}> */}
        <div>
          {<Figure image={image} />}
          <i
            className={!heart ? "icon-Hearty" : "icon-HeartFill"}
            onClick={AddToHeart}
          ></i>
          <i className="icon-Share"></i>
        </div>
        <div>
          <div className="ItemDesc">
            <br />
            <br />
            <ul>
              <li>Línea</li>
              <li>Categoría</li>
            </ul>
            <h6>Nombre del artículo</h6>
            <span>$000.00 MXN</span>
            <em>Detalles del artículo</em>
            <p>
              Sorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus.
            </p>
            <br />
            <br />
          </div>
        </div>
      </article>
    </>
  );
};

export default Component;
