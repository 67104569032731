import React from "react";
import { useNavigate, Link } from "react-router-dom";
import ItemTarjeta from "../../components/ItemTarjeta";
const Component = () => {
  const navigate = useNavigate();
  return (
    <section className="CheckoutBox">
      <article>
        <div className="StepCheckout">
          <div className="Selected"></div>
          <div className="Selected"></div>
          <div className="Selected"></div>
          <div></div>
        </div>
        <div className="icon-Wallet"></div>
        <h5>Información de pago</h5>
        <div className="TarjetasGuardadas">
          <div>Tarjetas guardadas</div>
          <div className="FontSmall">
            Selecciona la dirección a donde enviaremos tu compra.
          </div>
          {<ItemTarjeta tarjetaicon="VISAIcon" numero="... ... ... 6745" />}
          {
            <ItemTarjeta
              tarjetaicon="MastercardIcon"
              numero="... ... ... 6745"
            />
          }
          <div className="ButtonLightMedium">
            <i>+</i>
            AGREGAR TARJETA
          </div>
        </div>
        <div className="FormTarjeta">
          <div>Agregar tarjeta</div>
          <div className="FontSmall">
            Agrega la información de la nueva tarjeta.
          </div>
          <label className="FormData">
            <em className="RequiredData">Titular de la tarjeta</em>
            <div className="FormInputLight">
              <input placeholder="Nombre del titular" />
            </div>
          </label>
          <label className="FormData">
            <em className="RequiredData">Número de tarjeta</em>
            <div className="FormInputLight">
              <input placeholder="Número de tarjeta" />
            </div>
          </label>
          <div className="Row">
            <label className="FormData">
              <em className="RequiredData">Fecha de expiración</em>
              <div className="FormInputLight">
                <input placeholder="15/Marzo/2025" />
                <i className="InputIcon icon-Select"></i>
              </div>
            </label>
            <label className="FormData">
              <em className="RequiredData">CVV</em>
              <div className="FormInputLight">
                <input placeholder="XXX" />
              </div>
            </label>
          </div>
          <div className="ButtonLightMedium">GUARDAR TARJETA</div>
        </div>
        <div className="TarjetasGuardadas">
          <div>PayPal</div>
          <div className="FontSmall">
            Tu pago se realizará desde tu cuenta de PayPal
          </div>
          {
            <ItemTarjeta
              tarjetaicon="PayPalIcon"
              numero="cuentadepaypal@ejemplo.com"
            />
          }
        </div>
        <div className="TarjetasGuardadas">
          <div>Oxxo</div>
          <div className="FontSmall">
            Recibirás un comprobante para realizar tu pago en tiendas Oxxo
          </div>
          {<ItemTarjeta tarjetaicon="OXXOIcon" numero=".... .... .... 3465" />}
        </div>
        <div
          className="ButtonDarkMedium"
          onClick={() => navigate("/checkout-exito")}
        >
          REALIZAR COMPRA
          <i className="icon-Check"></i>
        </div>
      </article>
    </section>
  );
};

export default Component;
