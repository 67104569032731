import logo from "../assets/icons/ArtOnU.svg";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const Component = ({ wishlist }) => {
  const [selectedOptionLang, setSelectedOptionLang] = useState("es");
  const [selectedOptionCurrency, setSelectedOptionCurrency] = useState("mxn");
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const handleChangeLang = (event) => {
    setSelectedOptionLang(event.target.value);
  };
  const handleChangeCurrency = (event) => {
    setSelectedOptionCurrency(event.target.value);
  };
  const handleSearch = () => {
    if (searchValue) {
      navigate("/resultados");
    } else {
      navigate("/sin-resultados");
    }
  };
  return (
    <header className="Column">
      {/*<img src={logo} alt="logo" />*/}
      <div className="HeaderFirst">
        <div
          className="ImageIcon ArtOnU"
          onClick={() => navigate("/home")}
        ></div>
        <div className="HeaderMenus">
          <label className="FormData">
            <div className="FormInputMedium">
              <input
                placeholder="Buscar un producto"
                type="search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <i className="InputIcon icon-Search" onClick={handleSearch}></i>
            </div>
          </label>
          <div className="Bubbles Row">
            <div className="icon-User" onClick={() => navigate("/mi-perfil")}>
              {" "}
              Mi cuenta
            </div>
            <div className="icon-Hearty" onClick={() => navigate("/wishlist")}>
              {wishlist ? <div className="Bubble">{wishlist}</div> : ""}
            </div>
            <div
              className="icon-Trolley"
              onClick={() => navigate("/carrito")}
            ></div>
            <div className="icon-Burguer"></div>
          </div>
        </div>
      </div>
      <div className="HeaderSelect">
        <label>
          <div>
            <select value={selectedOptionLang} onChange={handleChangeLang}>
              <option value="es">Español</option>
              <option value="en">Ingles</option>
              <option value="it">Italiano</option>
              <option value="fr">Frances</option>
            </select>
            <i className="icon-ChevronDown"></i>
          </div>
        </label>
        <label>
          <div>
            <select
              value={selectedOptionCurrency}
              onChange={handleChangeCurrency}
            >
              <option value="mxn">$ MXN</option>
              <option value="usd">$ USD</option>
            </select>
            <i className="icon-ChevronDown"></i>
          </div>
        </label>
        {/*<div>
          <div>
            $ MXN <i className="icon-ChevronDown"></i>
          </div>
        </div>*/}
      </div>
    </header>
  );
};

export default Component;
