import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import PodriaInteresarte from './PodriaInteresarte';
const Component = ({ databc = [] }) => {
	return (
		<>
			<Breadcrumb databc={databc} />
			<section className='ResultadosZero'>
				<i className='icon-Magnifying'></i>
				<div className='Ups'></div>
				<b>¡No hemos encontrado nada!</b>
				<p>No hemos encontrado nada relacionado con tu<br />búsqueda, intenta utilizar otros términos de búsqueda.</p>
			</section>
			<PodriaInteresarte />
		</>
	);
};

export default Component;