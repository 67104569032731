import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import ItemTarjeta from "../../components/ItemTarjeta";
import ItemDireccion from "../../components/ItemDireccion";
import { Person1 } from "../../components/Images";
const Component = () => {
  const ItemDatos = (data, noedit) => {
    return (
      <div className="ItemData">
        <div className="Selected">
          <div>{data}</div>
          {!noedit && <div className="EditarTarjeta">Editar</div>}
        </div>
      </div>
    );
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="PerfilContent">
        <Breadcrumb title="MiPerfil" perfil />
        <div className="ArtFans BlackIconPerfil ArtFansPerfil"></div>
        <section className="PerfilInfo">
          <div className="PerfilBox">
            <div className="ProfilePic">
              <div>
                <div
                  className="ProfileImage"
                  style={{ backgroundImage: `url(${Person1})` }}
                ></div>
                <div>
                  <h5>Rafael Sánchez</h5>
                  <em className="EditarPerfil FontMedium">Editar</em>
                </div>
              </div>
              <div
                className="ButtonDark ButtonContent"
                onClick={() => navigate("/mis-pedidos")}
              >
                MIS PEDIDOS
              </div>
            </div>
            <div className="ProfileCard">
              <div className="Card">
                <div className="ArtFans"></div>
                <em className="FontSmall">Categoría:</em>
                <h3>AMBER</h3>
                <div className="FontMedium">
                  <span>Número de afiliación</span>
                  <span>1234 57680</span>
                </div>
              </div>
              <div className="Level">
                <div>
                  Estas muy cerca de desbloquear la categoría: <b>Esmerald</b>
                </div>
                <div>
                  <div className="Selected"></div>
                  <div className="Selected"></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
        <section className="PerfilData">
          <article>
            <div className="icon-IdentificationCard"></div>
            <b>Mis Datos</b>
            {ItemDatos("Télefono: +52 00 0000 0000")}
            {ItemDatos("Email: mail@ejemplo.com")}
            {ItemDatos("No. Afiliación: 1234 57680 ", true)}
          </article>
          <article>
            <div className="icon-HouseLine"></div>
            <b>Direcciones guardadas</b>
            <em>Direcciones guardadas para entrega de tus compras.</em>
            {<ItemDireccion stored />}
            {<ItemDireccion stored />}
            <div className="ButtonLight ButtonContent">
              <i>+</i>
              AGREGAR OTRA DIRECCIÓN
            </div>
          </article>
          <article>
            <div className="icon-Wallet"></div>
            <b>Tarjetas guardadas</b>
            <em>Tarjetas guardadas para realizar tus compras</em>
            {
              <ItemTarjeta
                stored
                tarjetaicon="VISAIcon"
                numero="... ... ... 6745"
              />
            }
            {
              <ItemTarjeta
                stored
                tarjetaicon="MastercardIcon"
                numero="... ... ... 3465"
              />
            }
            <div className="ButtonLight ButtonContent">
              <i>+</i>
              AGREGAR TARJETA
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default Component;
