import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image9,
} from "../../components/Images";
import { AddToHeart } from "../../components/Funciones";
import Breadcrumb from "../../components/Breadcrumb";
import ProductoAgotadoBox from "./ProductoAgotadoBox";
import PodriaInteresarte from "./PodriaInteresarte";

const Component = ({ disponible, tallasgenerales, tallas = [], talla }) => {
  const thumbnails = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];
  const ImageActiveIndex = 1;
  const ThumbnailRef = useRef(null);
  const TallasRef = useRef(null);
  const GranImagen = useRef(null);
  let thumbnailActive = 0;
  let tallasActive = 0;
  function CurrentPosTalla() {
    return tallasgenerales.findIndex((obj) => obj === talla);
  }
  function Thumbnail() {
    return (
      <ul ref={ThumbnailRef}>
        {thumbnails.map((image, index) => (
          <li
            style={{ backgroundImage: `url(${image})` }}
            className={index === ImageActiveIndex ? "Selected" : ""}
          ></li>
        ))}
      </ul>
    );
  }
  function ThumbnailPos(plus) {
    let thumbnailParent = document.querySelector(".Thumbnail");
    thumbnailActive = +thumbnailParent.dataset.i;
    if (plus === 1 && thumbnailActive < thumbnails.length - 3) {
      thumbnailParent.dataset.i = thumbnailActive += 1;
    }
    if (plus !== 1 && thumbnailActive > 0) {
      thumbnailParent.dataset.i = thumbnailActive -= 1;
    }
    let extra = 2.8 * (thumbnailActive - 1);
    extra = extra < 0 ? 0 : extra;
    let left = 32 * thumbnailActive;
    left += extra;
    ThumbnailRef.current.firstElementChild.style.marginLeft = `-${left}%`;
    Array.from(ThumbnailRef.current.children).forEach((thumbnail, index) => {
      thumbnail.classList.remove("Selected");
      if (thumbnailActive + 1 === index) {
        thumbnail.classList.add("Selected");
        GranImagen.current.style.backgroundImage =
          thumbnail.style.backgroundImage;
      }
    });
    //console.log(thumbnailActive, ThumbnailRef.current);
  }
  function TallasPos(plus) {
    //SeleccionaTalla
    console.log(CurrentPosTalla());
    let tallasParent = document.querySelector(".SeleccionaTalla");
    tallasActive = +tallasParent.dataset.i;
    if (plus === 1 && tallasActive < tallasgenerales.length - 4) {
      tallasParent.dataset.i = tallasActive += 1;
    }
    if (plus !== 1 && tallasActive > 0) {
      tallasParent.dataset.i = tallasActive -= 1;
    }
    let extra = 1.15 * (tallasActive - 1);
    extra = extra < 0 ? 0 : extra;
    let left = 26 * tallasActive;
    left -= extra;
    TallasRef.current.firstElementChild.style.marginLeft = `-${left}%`;
  }
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb databc={["T-Shirts", "Nombre del producto"]} />
      {disponible == 0 && <ProductoAgotadoBox />}
      <section className="DetallesProducto">
        <div>
          {/* ColeccionItems */}
          <div className="ProductoImagenes">
            <div
              ref={GranImagen}
              style={{
                backgroundImage: `url(${thumbnails[ImageActiveIndex]})`,
              }}
            >
              {disponible != 0 && (
                <div className="RotateFlag">
                  <div className="icon-CursorClick">WEB EXCLUSIVE</div>
                  <div className="icon-CrownSimple">LIMITED EDITION</div>
                </div>
              )}
              <div>
                <i className="icon-Hearty" onClick={AddToHeart}></i>
                <i className="icon-Share"></i>
              </div>
            </div>
            <div className="Thumbnail" data-i={thumbnailActive}>
              <div className="icon-CaretLeft" onClick={ThumbnailPos}></div>
              {Thumbnail()}
              <div
                className="icon-CaretRight"
                onClick={() => ThumbnailPos(1)}
              ></div>
            </div>
          </div>
          <div className="ProductoDescripcion">
            <div>
              <div className="ItemDesc">
                {disponible == 0 && (
                  <div className="NoDisponible">Producto NO DISPONIBLE</div>
                )}
                <h4>Nombre del artículo</h4>
                <ul>
                  <li>Colección</li>
                </ul>
                <ul>
                  <li>Línea</li>
                  <li>Categoría</li>
                </ul>
                <h6>$000.00 MXN</h6>
                <em>Detalles del artículo</em>
              </div>
              <div className="SeleccionaTalla" data-i={tallasActive}>
                <h6>Selecciona la talla:</h6>
                <div>
                  <div className="icon-CaretLeft" onClick={TallasPos}></div>
                  <ul ref={TallasRef}>
                    {tallasgenerales.map((item, index) => (
                      <li
                        key={index}
                        className={`${
                          talla == item ? "Selected" : ""
                        } ButtonLightMedium ${
                          tallas.includes(item) ? "" : "Disabled"
                        }`}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                  <div
                    className="icon-CaretRight"
                    onClick={() => TallasPos(1)}
                  ></div>
                </div>
              </div>
              {disponible != 0 ? (
                <>
                  <div className="EncuentraTalla">
                    <i className="icon-Shirt"></i>
                    <em>Encuentra tu talla</em>
                  </div>
                  <div className="ButtonDarkMedium">
                    <i className="icon-Trolley"></i>
                    AGREGAR AL CARRITO
                  </div>
                  <div
                    className="ButtonLightMedium"
                    onClick={() => navigate("/checkout-envio")}
                  >
                    COMPRAR AHORA
                  </div>
                </>
              ) : (
                <div className="ButtonDarkMedium">
                  <i className="icon-Bell"></i>
                  AVISARME CUANDO ESTE DISPONIBLE
                </div>
              )}
              <div className="TeAvisaremos Hide">
                <i className="icon-Check"></i>
                <span>
                  Te enviaremos un correo cuando este producto esté disponible.
                </span>
              </div>
              <div>
                <h6>Detalles del producto:</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat incididunt ut
                  labore.
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat incididunt ut
                  labore.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PodriaInteresarte title="Productos relacionados" />
    </>
  );
};
export default Component;
